<template>
    <travio-center-container grid-width='full'>
      <vs-breadcrumb :separator="'chevron_right'" class="mb-3">
        <li>
          <router-link class="vs-breadcrumb--link" :to="`/applications/${applicationId}/currencyExchange`">Currency Exchange</router-link>
          <span aria-hidden="true" class="separator notranslate vs-breadcrum--separator material-icons">
            chevron_right
          </span>
        </li>
        <li>
          <span class="vs-breadcrumb--text vs-breadcrumb-text-primary">Currency Manager</span>
        </li>
      </vs-breadcrumb>
      <h2 style="color:#636363" class="mb-4">Currency Manager</h2>
      <vx-card>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button class="mr-4 sm:mb-0 mb-2" color="primary" @click="onAddCurrency" type="filled">Add</vs-button>      
            <vs-input
              class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search..."
            />
          </div>
        </div>
         <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="currencyList"
          :gridOptions="gridOptions"
          :autoGroupColumnDef="autoGroupColumnDef"
          :rowDragManaged="true"
          :animateRows="true"
          rowSelection="single"
          :pagination="false"
          :context="context"
          :suppressPaginationPanel="true"
          @first-data-rendered="onFirstDataRendered"
          @grid-ready="onGridReady"
        >
        </ag-grid-vue>
        
        
      </vx-card>
    
    </travio-center-container>
  </template>
  
  <script>
      
  import {AgGridVue} from "ag-grid-vue";
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
  import Vue from "vue"
  import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';
  import CellRendererActiveCheckbox from './cell-renderers/CellRendererActiveCheckbox.vue'
  import CellRendererCurrencyActions from './cell-renderers/CellRendererCurrencyActions.vue'
  import { format } from 'date-fns'
  
  export default {
    components: {
      AgGridVue,
      CellRendererActiveCheckbox,
      CellRendererCurrencyActions
    },
    props: {
      applicationId: { required: true}
    },
    data () {
      return {
        currencyList: [],
        autoGroupColumnDef: null,
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        columnDefs: null,
        defaultColDef: null,
        rowSelection: null,
        cellRendererComponents: {
          CellRendererActiveCheckbox,
          CellRendererCurrencyActions
        },
        context: null,
        searchQuery: ''
      }
    },
    computed: {
      activeUserInfo() {
        return this.$store.state.AppActiveUser;
      },
    },
    setup () {
      const { appHasFeature } = useAppFeatureAccess();
      return { appHasFeature }
    },
    async created () {
      //Check if user has acccess to the app
      if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
        this.$router.push('/error-404')
      }
  
      if (!this.appHasFeature('currencyexchange')) {
        this.$router.push('/error-404')
      }
      // this.currencyList = [
      //   { id: 1, code: 'EUR', name: 'Euro', smallestDenomination: '100.3', availableStock: 90, minAmountForBoost: 1.5, orderId: 1,  isActive: true},
      //   { id: 2, code: 'USD', name: 'US Dollar', smallestDenomination: '50.5', availableStock: 120, minAmountForBoost: 1.5, orderId: 2,  isActive: true},
        
      // ]
  
      this.$vs.loading()
  
      try {
        const response = await this.$http.get(`api/currencyExchanges/apps/${this.applicationId}/currencies`)
        this.currencyList = response.data
  
      } catch (error) {
        this.$_notifyFailure(error)
      }
  
      this.$vs.loading.close();
  
    },

    beforeMount() {
  
      this.gridOptions = {};
  
      // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
      this.columnDefs =  [
        { field: 'id', hide: true },
        { field: 'orderId', hide: true },
        { headerName: 'Code', field: 'code', rowDrag: true, width: 120,  suppressSizeToFit: false },
        { headerName: 'Name', field: 'name', width: 180,  suppressSizeToFit: false },
        { headerName: 'Smallest Denominatinon', field: 'smallestDenomination', width: 180,  suppressSizeToFit: false },
        { headerName: 'Available Stock', field: 'availableStock', width: 180,  suppressSizeToFit: false },
        { headerName: 'Min. Amt. for Boost', field: 'minAmountForBoost', width: 180,  suppressSizeToFit: false },
        { headerName: 'API Rate', field: 'apiRate', width: 120,  suppressSizeToFit: false },
        { headerName: 'Active', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActiveCheckbox) },
        { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererCurrencyActions) },
        { field: 'isActive', hide: true },
      ]
  
      this.defaultColDef = {
        flex: 1,
        minWidth: 60,
        sortable: true,
        resizable: true,
        suppressMenu: true
      }
  
      this.autoGroupColumnDef = { minWidth: 200 };
      
      //This will make methods from this accessible to cell renderer
      this.context = { componentParent: this }
  
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
      this.gridApi.sizeColumnsToFit();

      this.gridOptions.onRowDragEnd = async (e) => { 
        //https://www.ag-grid.com/vue-grid/row-dragging/#row-drag-events

        const array_move = (arr, old_index, new_index) => {
          if (new_index >= arr.length) {
              var k = new_index - arr.length + 1;
              while (k--) {
                  arr.push(undefined);
              }
          }
          arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        }

        const rowNodeGettingDragged = e.node
        const fromIndex = this.$_.findIndex(this.currencyList, x => x.id == rowNodeGettingDragged.data.id)
        const toIndex = e.overIndex
        array_move(this.currencyList, fromIndex, toIndex)
        
        try {
          let response = await this.$http.put(
            `api/currencyExchanges/apps/${this.applicationId}/currencies/order`,
            { orderedItems: this.currencyList.map(m => ({ id: m.id }) ) })

          this.currencyList = [...this.currencyList]
          this.$_notifySuccess('Successfully reordered');
        }
        catch (error) {
          this.$_notifyFailure(error)
        }
        
      }
  
    },
    methods: {
      onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
      },
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
      },
      updateSearchQuery (val) {
        this.gridApi.setQuickFilter(val)
      },
      onAddCurrency () {
        this.$router.push({name: 'application-currency-exchange-currencies-add', 
          params: { 
            applicationId: this.applicationId,
          }
        })
      },
      editCurrency (currencyId) {
        this.$router.push({name: 'application-currency-exchange-currencies-edit', 
          params: { 
            applicationId: this.applicationId,
            currencyId: currencyId 
          }
        })
      },
    }
  }
  </script>
  
  <style>
  
  </style>